<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    :readonly="load || bloquear_caja"
                    :filled="load || bloquear_caja"
                    hide-details
                    outlined
                    dense
                    @change="limpiar(1); filtro.cj_numero = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    :readonly="load || bloquear_caja"
                    :filled="load || bloquear_caja"
                    hide-details
                    outlined
                    dense
                    @change="limpiar(1); filtro.cj_numero = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3" class="py-1">
                  Caja
                  <v-autocomplete
                    v-model="filtro.cj_numero"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="cajas_user.filter(cj => cj.empresa == filtro.empresa && cj.sucursal == filtro.sucursal)"
                    :readonly="load || bloquear_caja"
                    :filled="load || bloquear_caja"
                    hide-details
                    outlined
                    dense
                    @change="getCaja()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" class="py-1">
                  <span class="ml-10">Nº Caja</span>
                  <v-text-field
                    v-model="filtro.numero"
                    tabindex="1"
                    type="number"
                    :readonly="load"
                    :filled="load"
                    hide-details
                    outlined
                    dense
                    @change="filtro.fecha = null; caja.turno = null; caja.estado = null; caja.fecha_cierre = null; keyFecha ++"
                  >
                    <template v-slot:prepend>
                      <v-btn
                        elevation="3"
                        color="info"
                        class="mt-n1"
                        title="Anterior"
                        :disabled="!existe_caja || load"
                        x-small
                        fab
                        @click="getNextPrev('ANTERIOR')"
                      >
                        <v-icon>fas fa-arrow-left</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn
                        elevation="3"
                        color="info"
                        class="mt-n1"
                        title="Siguiente"
                        :disabled="!existe_caja || load || lastCaja.numero == caja.numero"
                        x-small
                        fab
                        @click="getNextPrev('SIGUIENTE')"
                      >
                        <v-icon>fas fa-arrow-right</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Fecha
                  <FechaPicker
                    v-model="filtro.fecha"
                    :key="keyFecha"
                    :disabled="filtro.cj_numero == null || load"
                    hide-details
                    @change="filtro.numero = null; caja.turno = null; caja.estado = null; caja.fecha_cierre = null;"
                  />
                </v-col>
                <v-col v-if="caja.fecha_cierre != null" cols="6" sm="3" md="2" class="py-1">
                  Fecha cierre
                  <FechaPicker
                    v-model="caja.fecha_cierre"
                    hide-details
                    disabled
                  />
                </v-col>
                <v-col cols="6" sm="2" md="1" class="py-1">
                  Turno
                  <v-text-field
                    v-model="caja.turno"
                    type="number"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Estado
                  <v-text-field
                    v-model="caja.estado"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex justify-end align-end mt-0">
                  <!-- <v-btn
                    color="info"
                    type="submit"
                    tabindex="1"
                    :loading="load"
                  >
                    <v-icon left>fas fa-search</v-icon>
                    Buscar
                  </v-btn> -->
                  <BtnFiltro
                    nombre="Buscar"
                    icono="fas fa-search"
                    :loading="load"
                    clase="mt-0"
                    @clear="limpiar(2)"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- BOTONES -->
      <v-row class="d-flex justify-end mr-0 pl-1 mb-4 mt-2">
        <v-btn
          v-if="caja.estado_id == 1"
          class="mt-2 ml-2"
          color="teal"
          :disabled="load"
          :dark="!load"
          @click="dialog_comprobante = true; comp_id = null"
        >
          <v-icon small left>fas fa-plus</v-icon>
          Agregar
        </v-btn>
        <!-- 27-02-2024 por MMURILLO, permitimos hacer extracciones de efectivo a los clientes sin la necesidad de grabar una venta -->
        <v-btn
          v-if="caja.estado_id == 1"
          class="mt-2 ml-2"
          color="teal"
          :disabled="load"
          :dark="!load"
          @click="extraerEfectivo()"
        >
          <v-icon small left>far fa-money-bill-alt</v-icon>
          Extraer Efectivo
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="lastCaja.estado == 1 && cajaNivel == 1"
          color="success"
          class="mt-2 ml-2"
          :disabled="load"
          @click="openWindow(null)"
        >
          <v-icon left>fas fa-shopping-cart</v-icon>
          Nueva venta
        </v-btn>
        <template v-if="lastCaja.numero == caja.numero || permite_abrirla.valor == 1">
          <v-btn
            v-if="(lastCaja.estado == 2 && permiso_open_close == 1) || permite_abrirla.valor == 1"
            class="mt-2 ml-2"
            color="success"
            :disabled="load"
            @click="dialog_abrir = true"
          >
            <v-icon small left>fas fa-cash-register</v-icon>
            Abrir caja
          </v-btn>
          <v-btn
            v-if="lastCaja.estado == 1 && permiso_open_close == 1"
            class="mt-2 ml-2"
            color="error"
            :disabled="load"
            @click="verificarPendientes()"
          >
            <v-icon small left>fas fa-cash-register</v-icon>
            Cerrar caja
          </v-btn>
        </template>
        <v-btn
          v-if="fpIntegrado == 1"
          class="mt-2 ml-2"
          color="blue darken-2"
          :disabled="load"
          :dark="!load"
          @click="dialog_cierre_lote = true"
        >
          <v-icon small left>fas fa-receipt</v-icon>
          Cierre de Lote
        </v-btn>
        <v-btn
          v-if="caja.estado_id != 1 && caja.numero != null && cajaNivel == 1"
          class="mt-2 ml-2"
          color="orange"
          :disabled="load"
          :dark="!load"
          @click="imprimirCierre()"
        >
          <v-icon small left>fas fa-print</v-icon>
          Reimprimir Cierre
        </v-btn>
      </v-row>
      <!-- DATA TABLES -->
      <v-card>
        <v-tabs
          v-model="tab"
          :vertical="$vuetify.breakpoint.xs"
          class="mt-2"
        >
          <v-tab>
            Operaciones
          </v-tab>
          <v-tab :disabled="cajaNivel != 2">
            Pendientes de aceptación
          </v-tab>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-spacer v-if="$vuetify.breakpoint.xl"></v-spacer>
          <v-col class="py-2">
            <SearchDataTable
              v-model="search"
            />
          </v-col>
        </v-tabs>
        <v-tabs-items v-model="tab" :touch="{}">
          <!-- COMPROBANTES -->
          <v-tab-item>
            <v-data-table
              class="cebra"
              :headers="headers"
              :items="comprobante_caja"
              :search="search"
              :loading="load"
              :footer-props="{
                'items-per-page-options':[5, 10, 15, 100],
                'show-first-last-page':true,
                'show-current-page':true
              }"
              mobile-breakpoint
              dense
            >
              <template v-slot:[`item.fecha`]="{ item }">
                {{ moment(item.fecha).format('DD/MM/YYYY') }}
                <v-tooltip color="indigo" right>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="info"
                      right
                      small
                    >
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  <div v-if="item.usuario_log == 'APIJARVIS' || item.usuario_log == 'TELECOM'">
                    Registro automático
                  </div>
                  <div v-else-if="item.rechazado == 1">
                    Motivo de rechazo: {{ item.motivo_rechazo }}
                  </div>
                  <div v-else>
                    Grabado por {{ item.usuario_log }} el {{ moment(item.fecha_log).format('DD/MM/YY [a las] HH:mm:ss') }}
                  </div>
                </v-tooltip>
              </template>
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- ultima row para el total -->
              <template slot="body.append">
                <tr class="font-weight-bold">
                  <th></th>
                  <th></th>
                  <th style="font-size: 14px">Total</th>
                  <th style="font-size: 14px" class="text-right">{{ format_money(total_efectivo) }}</th>
                  <th style="font-size: 14px" class="text-right">{{ format_money(total_tarjeta) }}</th>
                  <th style="font-size: 14px" class="text-right">{{ format_money(total) }}</th>
                  <th></th>
                </tr>
              </template>
              <!-- acciones -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="item.factura_electronica == 1"
                  title="Factura"
                  color="cyan"
                  class="mr-2"
                  :disabled="item.factura_bloqueada == 1"
                  small
                  @click="botonFactura(item)"
                >
                  fas fa-print
                </v-icon>
                <v-icon
                  v-if="item.nc_electronica == 1"
                  title="Nota de Crédito"
                  color="orange darken-3"
                  class="mr-2"
                  small
                  @click="botonFactura(item)"
                >
                  fas fa-file-alt
                </v-icon>
                <v-icon
                  v-if="item.modifica == 1 && item.estado == 1 && (caja.estado_id == 1 || permiso_modif_comp == 1)"
                  title="Modificar"
                  color="green darken-1"
                  class="mr-2"
                  small
                  @click="dialog_comprobante = true; comp_id = item.id"
                >
                  fas fa-pen
                </v-icon>
                <BtnConfirmar
                  v-if="item.anula == 1 && item.estado == 1 && (caja.estado_id == 1 || permiso_modif_comp == 1)"
                  clase="mr-2"
                  color="red"
                  title="Anular"
                  icono="fas fa-times-circle"
                  :texto="`¿Desea <strong>anular</strong> el comprobante <strong>Nº ${item.numero}</strong> por un total de
                    <strong>${format_money(Math.abs(item.importe))}</strong>?`"
                  icon_button
                  small
                  @action="anularComprobante(item)"
                />
                <v-icon
                  v-if="item.aceptacion != 0"
                  title="Recibo Aceptación"
                  color="amber darken-3"
                  class="mr-2"
                  small
                  @click="descargarAceptacion(item.aceptacion)"
                >
                  fsa fa-arrow-alt-circle-down
                </v-icon>
                <v-icon
                  v-if="item.digitaliza == 1"
                  title="Subir Archivos"
                  :color="item.con_archivos > 0 ? 'light-green accent-4' : 'red accent-3'"
                  class="mr-2"
                  small
                  @click="verImagenes(item)"
                >
                  fsa fa-cloud-upload-alt
                </v-icon>
                <v-icon
                  v-if="item.factura_electronica == 1 && item.estado == 1"
                  title="Enviar Factura"
                  color="light-green accent-4"
                  class="mr-2"
                  small
                  @click="confirmarEnvioMail(item)"
                >
                  far fa-envelope
                </v-icon>
                <!-- 01-03-2024 por MMURILLO, agregamos acciones para los retiros de efectivo manuales -->
                <v-icon
                  v-if="item.comprobante_tipo == 136 && (item.estado == 0 || item.estado == 1)"
                  title="Ver Extracción de Efectivo"
                  color="light-green accent-4"
                  class="mr-2"
                  small
                  @click="verExtraccion(item)"
                >
                  far fa-money-bill-alt
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>
          <!-- PENDIENTES DE ACEPTACION -->
          <v-tab-item>
            <v-data-table
              v-model="selected"
              class="cebra"
              :headers="headers"
              :items="comprobante_pendientes"
              :search="search"
              :loading="load"
              :footer-props="{
                'items-per-page-options':[5, 10, 15, 100],
                'show-first-last-page':true,
                'show-current-page':true
              }"
              mobile-breakpoint
              show-select
              dense
              @toggle-select-all="selectAllToggle"
            >
              <template v-slot:[`item.fecha`]="{ item }">
                {{ moment(item.fecha).format('DD/MM/YYYY') }}
                <v-tooltip color="indigo" right>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="info"
                      right
                      small
                    >
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  <div>
                    Grabado por {{ item.usuario_log }}
                    el {{ moment(item.fecha_log).format('DD/MM/YY [a las] HH:mm:ss') }}
                  </div>
                </v-tooltip>
              </template>
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- ultima row para el total -->
              <template slot="body.append">
                <tr class="font-weight-bold">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style="font-size: 14px">Total</th>
                  <th style="font-size: 14px" class="text-right">{{ format_money(total_efectivo_pend) }}</th>
                  <th style="font-size: 14px" class="text-right">{{ format_money(total_tarjeta_pend) }}</th>
                  <th style="font-size: 14px" class="text-right">{{ format_money(total_pend) }}</th>
                  <th></th>
                </tr>
              </template>
              <!-- acciones -->
              <template v-slot:[`item.actions`]="{ item }">
                <BtnConfirmar
                  clase="mr-2"
                  color="success"
                  title="Aceptar"
                  icono="fas fa-check"
                  :texto="`¿Desea <strong>aceptar</strong> el comprobante <strong>Nº ${item.numero}</strong> por un total de
                    <strong>${format_money(Math.abs(item.importe))}</strong>?`"
                  icon_button
                  small
                  @action="aceptarComprobantes(item)"
                />
                <v-icon
                  color="error"
                  title="Rechazar"
                  small
                  @click="dialog_rechazar = true; comp_pend = item"
                >
                  fsa fa-times
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <!-- BOTONES -->
      <v-row v-if="tab == 1" class="d-flex justify-end mx-0 mt-1 mb-3">
        <SelectedCounter
          :selected.sync="selected"
          :items.sync="comprobante_pendientes"
          class="mt-sm-1"
          custom
          @criterio="selectAllPendientes()"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          class="mt-2"
          :disabled="selected.length <= 1"
          @click="dialog_rechazar = true; comp_pend = null"
        >
          <v-icon small left>fas fa-times</v-icon>
          Rechazo masivo
        </v-btn>
        <BtnConfirmar
          color="success"
          clase="mt-2 ml-3"
          icono="fas fa-check"
          nombre="Aceptación masiva"
          :texto="`¿Desea <strong>aceptar</strong> los <strong>${selected.length}</strong> comprobantes seleccionados por un total de
            <strong>${format_money(selected.reduce((sum, cp) => sum + Math.abs(cp.importe), 0))}</strong>?`"
          :disabled="selected.length <= 1"
          @action="aceptarComprobantes()"
        />
      </v-row>
    </v-col>
    <!-- COMPONENTES -->
    <ModalCierreLote
      v-model="dialog_cierre_lote"
      :ptovta="localCodigo"
    />
    <AbrirCaja
      v-model="dialog_abrir"
      :cj_numero="caja.cj_numero"
      :transporte="lastCaja.transporte"
      :local_nombre="localNombre"
      :permite_abrirla="permite_abrirla"
      @abierta="getCaja()"
    />
    <CerrarCaja
      v-model="dialog_cerrar"
      :efectivo="total_efectivo"
      :tarjeta="total_tarjeta"
      :total="total"
      :comp_caja="comprobante_caja"
      :integrado="fpIntegrado"
      :cj_numero="caja.cj_numero"
      :local_nombre="localNombre"
      @cerrada="cajaCerrada()"
      @diferencia="findCaja()"
    />
    <ModalComprobante
      v-model="dialog_comprobante"
      :cj_numero="caja.cj_numero"
      :ca_numero="caja.numero"
      :ca_estado="caja.estado_id"
      :ca_nivel="cajaNivel"
      :comp_id="comp_id"
      @deposito="openDeposito"
      @exito_comprobante="findCaja()"
    />
    <ModalDeposito
      v-model="dialog_deposito"
      :cj_numero="caja.cj_numero"
      :ca_numero="caja.numero"
      :ca_nivel="cajaNivel"
      :origen="origenMovimiento"
      :concepto="depConcepto"
      :referencia="depReferencia"
      :num_comp="depNumCmp"
      :importe="depImporte"
      :empresa="caja.empresa"
      :comp_id="comp_id"
      @exito_deposito="findCaja()"
    />
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
    <ArchivosCaja
      v-model="dialog_archivos"
      :titulo="titulo"
      :comp_id="comp_id"
      :caja_num="caja.numero"
    />
    <!-- modal de recahzar pendientes -->
    <v-dialog
      v-model="dialog_rechazar"
      width="500"
    >
      <v-card>
        <v-card-title>
          Rechazar comprobante{{ comp_pend == null ? 's' : '' }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_rechazar = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="body-1 pt-4 pb-2">
          <span v-if="comp_pend != null">
            ¿Desea <strong>rechazar</strong> el comprobante <strong>Nº {{ comp_pend.numero }}</strong> por un total de
            <strong>{{ format_money(Math.abs(comp_pend.importe)) }}</strong>?
          </span>
          <span v-else>
            ¿Desea <strong>rechazar</strong> los <strong>{{ selected.length }}</strong> comprobantes seleccionados por un total de
            <strong>{{ format_money(selected.reduce((sum, cp) => sum + Math.abs(cp.importe), 0)) }}</strong>?
          </span>
        </v-card-text>
        <v-card-text class="body-1 pt-4">
          <v-textarea
            v-model.trim="motivo"
            label="Motivo"
            rows="2"
            hide-details
            auto-grow
            outlined
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            @click="dialog_rechazar = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="motivo == ''"
            @click="rechazarComprobantes()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modal de opciones de factura -->
    <v-dialog
      v-model="dialog_opciones_fac"
      width="400"
    >
      <v-card>
        <v-card-title>
          Seleccione una acción
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_opciones_fac = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  class="pa-3"
                  :class="hover ? 'white--text' : ''"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : ''"
                  @click="imprimirFactura(comp_item)"
                >
                  <v-icon
                    :color="hover ? 'white' : 'primary'"
                    left
                  >
                    fas fa-print
                  </v-icon>
                  Imprimir
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="6">
              <v-hover v-slot="{ hover }">
                <v-card
                  style="cursor: pointer"
                  class="pa-3"
                  :class="hover ? 'white--text' : ''"
                  :elevation="hover ? '10' : ''"
                  :color="hover ? 'primary' : ''"
                  @click="descargarFactura(comp_item)"
                >
                  <v-icon
                    :color="hover ? 'white' : 'primary'"
                    left
                  >
                    fas fa-eye
                  </v-icon>
                  Visualizar
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Enviar Email -->
    <ModalEnviarEmail 
      :datos="dialogEnviarMail"
      @setearModalEnvEmail="setModalEnvEmail"
    />
    <!-- Modal Extraer Efectivo para Clientes -->
    <ModalExtraerEfectivo 
      :datos="dialogExtracciones"
      @setearModalRetEfect="setModalRetEfect"
      @retiroExitoso="setRetiroEfectivo"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import download from 'downloadjs'
import * as JSPM from 'jsprintmanager'
import { mapState, mapGetters } from 'vuex'
import { format_money, ArrayBufferToBase64 } from '../../util/utils'
import { operacionesCajaTermica, operacionesCajaA4, operacionesAceptacionA4, facturaTermica, facturaA4 } from '../../util/plantillas/pdfs'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import FechaPicker from '../../components/util/FechaPicker'
import SearchDataTable from '../../components/util/SearchDataTable'
import ModalCierreLote from '../../components/ventas/ModalCierreLote'
import CerrarCaja from '../../components/caja/CerrarCaja'
import AbrirCaja from '../../components/caja/AbrirCaja'
import PDFViewer from '../../util/plantillas/PDFViewer'
import ModalComprobante from '../../components/caja/ModalComprobante'
import ModalDeposito from '../../components/caja/ModalDeposito'
import SelectedCounter from '../../components/util/SelectedCounter.vue'
import ArchivosCaja from '../../components/caja/ArchivosCaja.vue'
import ModalEnviarEmail from '../../components/caja/ModalEnviarEmail.vue'
import ModalExtraerEfectivo from '../../components/caja/ModalExtraerEfectivo.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'

export default {
  data () {
    return {
      moment: moment,
      format_money: format_money,
      tab: 0,
      panel: 0,
      keyFecha: 0,
      total: 0,
      total_efectivo: 0,
      total_tarjeta: 0,
      total_pend: 0,
      total_efectivo_pend: 0,
      total_tarjeta_pend: 0,
      permiso_open_close: 0,
      permiso_modif_comp: 0,
      permiso_anula_comp: 0,
      permiso_descargar_factura: 0,
      dialog_abrir: false,
      dialog_cerrar: false,
      dialog_cierre_lote: false,
      dialog_comprobante: false,
      dialog_deposito: false,
      dialog_rechazar: false,
      dialog_archivos: false,
      dialog_opciones_fac: false,
      comp_item: {},
      comp_pend: {},
      bloquear_caja: false,
      existe_caja: false,
      load: false,
      motivo: '',
      search: '',
      titulo: '',
      pdf: null,
      pdf_nombre: '',
      pdf_dialog: false,
      selected: [],
      comprobante_caja: [],
      comprobante_pendientes: [],
      headers: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Nº de Comprobante', value: 'numero', cellClass: 'font-weight-bold' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Efectivo', value: 'efectivo', align: 'end', formatter: format_money },
        { text: 'Tarjeta', value: 'tarjeta', align: 'end', formatter: format_money },
        { text: 'Total', value: 'importe', align: 'end', cellClass: 'font-weight-bold', formatter: format_money },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      lastCaja: {
        estado: null,
        fecha: null,
        numero: null,
        transporte: null
      },
      caja: {},
      cajaCanal: null,
      cajaNivel: null,
      localCodigo: null,
      localNombre: null,
      fpIntegrado: null,
      impresionAutomatica: null,
      origenMovimiento: null,
      comp_id: null,
      depConcepto: null,
      depReferencia: null,
      depNumCmp: null,
      depImporte: null,
      cajaLimpia: {
        empresa: null,
        sucursal: null,
        cj_numero: null,
        numero: null,
        fecha: null,
        fecha_apertura: null,
        fecha_cierre: null,
        estado_id: null,
        estado: null,
        turno: null
      },
      filtro: {
        empresa: null,
        sucursal: null,
        cj_numero: null,
        numero: null,
        fecha: null
      },
      dialogEnviarMail: {
        activo: false,
        cliente: '',
        nro_comprobante: '',
        factura: '',
        email: ''
      },
      dialogExtracciones: {
        activo: false,
        lapos_integrado: 0,
        lapos_desintegrado: 0,
        efectivo_disponible: 0,
        sucursal_codigo: 0,
        empresa_codigo: 0,
        canal_codigo: 0,
        pto_vta: 0,
        comp_caja_id: 0,
        nuevo: 0
      },
      permite_abrirla: {
        valor: 0,
        cj_numero: null
      }
    }
  },
  async created () {
    this.caja = JSON.parse(JSON.stringify(this.cajaLimpia))
    // si tiene solo 1 empresa/sucursal la selecciono
    if (this.una_empresa) this.filtro.empresa = this.empresas[0].id
    if (this.una_sucursal) this.filtro.sucursal = this.sucursales[0].id
    // obtengo los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('caja/get_cajas_user')
    let res_permiso = await this.$store.dispatch('caja/get_permisos_user')
    if (res_permiso.exito == 1) {
      this.permiso_open_close = res_permiso.permisos.abrir_cerrar
      this.permiso_modif_comp = res_permiso.permisos.modif_comp
      this.permiso_anula_comp = res_permiso.permisos.anula_comp
      this.permiso_descargar_factura = res_permiso.permisos.descargar_factura
    } else {
      this.$store.dispatch('show_snackbar', {
        text: res_permiso.message,
        color: 'error'
      })
    }
    this.$store.state.loading = false
    // si tiene local favorito se lo autoselecciono solo si tiene 1 sucursal y 1 ptovta
    let favorito = this.cajas_user.filter(ca => ca.favorito == 1)
    if (favorito.length == 1 && this.una_empresa && this.una_sucursal) {
      this.filtro.cj_numero = favorito[0].id
      this.bloquear_caja = true
      this.getCaja()
    }
  },
  mounted () {
    this.JSPMInit()
  },
  computed: {
    ...mapState('caja', ['cajas_user']),
    ...mapState(['empresas', 'sucursales', 'locales']),
    ...mapGetters(['una_empresa', 'una_sucursal'])
  },
  components: {
    BtnConfirmar,
    FechaPicker,
    SearchDataTable,
    ModalCierreLote,
    CerrarCaja,
    AbrirCaja,
    PDFViewer,
    ModalComprobante,
    ModalDeposito,
    SelectedCounter,
    ArchivosCaja,
    ModalEnviarEmail,
    ModalExtraerEfectivo,
    BtnFiltro
  },
  watch: {
    'filtro.cj_numero': {
      handler: function (val) {
        this.cajaCanal = null
        this.cajaNivel = null
        this.localCodigo = null
        this.localNombre = null
        this.fpIntegrado = null
        this.impresionAutomatica = null
        this.origenMovimiento = null
        if (val != null) {
          let caja = this.cajas_user.filter(cu => cu.id == val)
          if (caja.length == 1) {
            this.cajaCanal = caja[0].canal
            this.cajaNivel = caja[0].nivel
            this.localCodigo = caja[0].local
            this.localNombre = caja[0].local_nombre
            this.fpIntegrado = caja[0].fp_integrado
            this.impresionAutomatica = caja[0].impresion_automatica
            switch (this.cajaNivel) {
              case 1:
                // ORIGEN PUNTO DE VENTA PARA MOVIMIENTOS BANCARIOS
                this.origenMovimiento = 2
                break
              case 2:
                // ORIGEN SUCURSAL PARA MOVIMIENTOS BANCARIOS
                this.origenMovimiento = 3
                break
            }
          }
        }
      },
      deep: true
    },
    dialog_rechazar (val) {
      if (!val) {
        this.motivo = ''
        this.comp_pend = null
      }
    }
  },
  methods: {
    botonFactura (comprobante) {
      // facturacion
      if (comprobante.factura_electronica == 1 && comprobante.estado == 0) {
        this.facturar(comprobante)
        return
      }
      // nota de credito
      if (comprobante.nc_electronica == 1 && comprobante.estado == 2) {
        this.notaCredito(comprobante)
        return
      }

      // si es mayorista directamente descargo
      if (comprobante.mayorista == 1) {
        this.descargarFacturaMayorista(comprobante)
        return
      }
      // si tiene permiso lo dejo elegir la accion
      if (this.permiso_descargar_factura == 1) {
        this.comp_item = comprobante
        this.dialog_opciones_fac = true
      }
      // si no tiene permiso comeinzo con las validaciones para ver q accion realizar
      else {
        if (this.impresionAutomatica == 1) {
          this.imprimirFactura(comprobante)
        } else {
          this.descargarFactura(comprobante)
        }
      }
    },
    async descargarFacturaMayorista (comprobante) {
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(comprobante.id)
      this.$store.state.loading = false
      if (datos == null) return

      this.$store.state.loading = true
      this.pdf = await facturaA4(datos)
      this.$store.state.loading = false
      this.pdf_nombre = `${datos.encabezado.referencia}-${datos.encabezado.emynum}`
      this.pdf_dialog = true
    },
    async descargarFactura (comprobante) {
      this.dialog_opciones_fac = false
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(comprobante.id)
      this.$store.state.loading = false
      if (datos == null) return

      this.$store.state.loading = true
      if (datos.encabezado.impresora_termica == 1) {
        this.pdf = await facturaTermica(datos)
      } else {
        this.pdf = await facturaA4(datos)
      }
      this.$store.state.loading = false

      this.pdf_nombre = `${datos.encabezado.referencia}-${datos.encabezado.emynum}`
      this.pdf_dialog = true
    },
    async imprimirFactura (comprobante) {
      this.dialog_opciones_fac = false
      this.$store.state.loading = true
      const datos = await this.getDatosFactura(comprobante.id)
      this.$store.state.loading = false
      if (datos == null) return

      this.$store.state.loading = true
      if (datos.encabezado.impresora_termica == 1) {
        this.doPrintPDF(await facturaTermica(datos))
      } else {
        this.doPrintPDF(await facturaA4(datos))
      }
      this.$store.state.loading = false

      comprobante.factura_bloqueada = 1
      setTimeout(() => (comprobante.factura_bloqueada = 0), 10000)
    },
    async getDatosFactura (comp_id) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/datos_factura', { comp_id: comp_id })
      this.$store.state.loading = false
      if (respuesta.exito == 1) {
        return respuesta.datos
      } else {
        this.$swal.fire({
          icon: 'error',
          title: respuesta.message
        })
        return null
      }
    },
    async notaCredito (comprobante) {
      let modal = await this.$swal.fire({
        icon: 'info',
        html: `<h2>¿Está seguro de emitir la Nota de Crédito para el pedido Nº ${comprobante.numero}?</h2>`,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      })
      if (!modal.isConfirmed) return

      this.$store.state.loading = true
      let ncPeticion = await this.$store.dispatch('afip/getNotaCredito', {
        comprobante_caja_id: comprobante.id
      })
      this.$store.state.loading = false

      if (ncPeticion.resultado == 1) {
        this.$swal.fire({
          icon: 'success',
          title: 'Se genero con éxito la Nota de Crédito',
          text: 'En breve se descargará el comprobante.',
          timer: 3000
        })

        this.$store.state.loading = true
        const datos = await this.getDatosFactura(comprobante.id)
        this.$store.state.loading = false

        if (datos != null) {
          this.$store.state.loading = true
          if (datos.encabezado.impresora_termica == 1) {
            this.pdf = await facturaTermica(datos)
          } else {
            this.pdf = await facturaA4(datos)
          }
          this.$store.state.loading = false

          if (this.impresionAutomatica == 1) {
            this.doPrintPDF(this.pdf)
          } else {
            download(this.pdf, `${datos.encabezado.referencia}-${datos.encabezado.emynum}.pdf`,'application/pdf')
          }

        }

        this.findCaja()
        
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'No fue posible generar la Nota de Crédito',
          text: ncPeticion.msj
        })
      }
    },
    async facturar (comprobante) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('afip/permiteFacturar', {
        comprobante_caja_id: comprobante.id
      })
      this.$store.state.loading = false

      if (respuesta.resultado != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: respuesta.msj
        })
      }
      
      let modal = await this.$swal.fire({
        icon: 'info',
        html: `<h2>¿Está seguro de emitir un comprobante del tipo: <strong>${respuesta.comprobante_tipo}</strong> para el pedido Nº ${comprobante.numero}?</h2>`,
        confirmButtonText: 'Facturar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      })
      if (!modal.isConfirmed) return

      this.$store.state.loading = true
      let fcPeticion = await this.$store.dispatch('afip/getFactura', {
        comprobante_caja_id: comprobante.id
      })
      this.$store.state.loading = false

      if (fcPeticion.resultado == 1) {
        this.$swal.fire({
          icon: 'success',
          title: 'Se generó con Éxito la Factura',
          text: 'En breve se descargará el comprobante.',
          timer: 3000
        })

        this.$store.state.loading = true
        const datos = await this.getDatosFactura(comprobante.id)
        this.$store.state.loading = false

        if (datos != null) {
          this.$store.state.loading = true
          if (datos.encabezado.impresora_termica == 1) {
            this.pdf = await facturaTermica(datos)
          } else {
            this.pdf = await facturaA4(datos)
          }
          this.$store.state.loading = false

          if (this.impresionAutomatica == 1) {
            this.doPrintPDF(this.pdf)
          } else {
            download(this.pdf, `${datos.encabezado.referencia}-${datos.encabezado.emynum}.pdf`,'application/pdf')
          }

        }

        this.findCaja()
        
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al generar la Factura',
          text: fcPeticion.msj
        })
      }
    },
    async descargarAceptacion (aceptacion) {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/informe_aceptacion_caja', aceptacion)

      if (respuesta.exito != 1) {
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: respuesta.message,
          color: 'error'
        })
      }

      this.pdf = await operacionesAceptacionA4(respuesta.datos)
      this.pdf_nombre = `Recepción Operaciones ${aceptacion}`
      this.pdf_dialog = true

      this.$store.state.loading = false
    },
    async rechazarComprobantes () {
      let motivo = this.motivo
      let comprobantes = this.comp_pend ? [this.comp_pend] : this.selected
      this.dialog_rechazar = false

      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/rechazar_comprobantes', {
        motivo: motivo,
        comprobantes: comprobantes.map(comp => { return { id: comp.id, numero: comp.numero } })
      })
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.getCaja()
        this.$swal.fire({
          icon: resultado.message == 'ok' ? 'success' : 'warning',
          title: resultado.message == 'ok' ? 'Se rechazaron los comprobantes pendientes' : 'Algunos comprobantes no se pudieron rechazar',
          html: resultado.message == 'ok' ? '' : resultado.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al rechazar los comprobantes pendientes',
          html: resultado.message
        })
      }
    },
    async aceptarComprobantes (item) {
      let comprobantes = item ? [item] : this.selected

      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/aceptar_comprobantes', {
        cj_numero: this.caja.cj_numero,
        comprobantes: comprobantes.map(comp => comp.id)
      })
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.getCaja()
        await this.$swal.fire({
          icon: resultado.message == 'ok' ? 'success' : 'warning',
          title: resultado.message == 'ok' ? 'Se aceptaron los comprobantes pendientes' : 'Algunos comprobantes no se pudieron aceptar',
          html: resultado.message == 'ok' ? '' : resultado.message
        })
        this.descargarAceptacion(resultado.aceptacion)
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al aceptar los comprobantes pendientes',
          html: resultado.message
        })
      }
    },
    async anularComprobante (item) {
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/anular_comprobante', item.id)
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.getCaja()
        this.$swal.fire({
          icon: 'success',
          title: resultado.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error al anular',
          text: resultado.message
        })
      }
    },
    async imprimirCierre () {
      if (this.cajaNivel == 1) {
        this.$store.state.loading = true
        let respuesta = await this.$store.dispatch('caja/informe_operaciones_caja', this.caja.numero)

        if (respuesta.exito != 1) {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: respuesta.message,
            color: 'error'
          })
        }

        if (respuesta.datos.impresora_termica == 1) {
          this.pdf = await operacionesCajaTermica(respuesta.datos)
          this.pdf_nombre = `${this.caja.numero}`
          this.pdf_dialog = true
        } else {
          this.pdf = await operacionesCajaA4(respuesta.datos)
          this.pdf_nombre = `Operaciones Caja ${this.caja.numero}`
          this.pdf_dialog = true
        }
        this.$store.state.loading = false
      }
    },
    async verificarPendientes () {
      if (this.cajaNivel < 2) {
        // cajas con nivel menor a 2 no se controla pendientes
        this.dialog_cerrar = true
        return
      } else {
        this.$store.state.loading = true
        let resultado = await this.$store.dispatch('caja/control_pendientes', this.caja.cj_numero)
        this.$store.state.loading = false

        if (resultado.exito == 1) {
          this.dialog_cerrar = true
        } else {
          this.$swal.fire({
            icon: 'error',
            title: 'No es posible cerrar caja',
            text: resultado.message
          })
        }
      }
    },
    async getNextPrev (funcion) {
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/get_next_prev', {
        numero: this.caja.numero,
        cj_numero: this.caja.cj_numero,
        funcion: funcion
      })
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.filtro.numero = resultado.caja.numero
        this.filtro.fecha = resultado.caja.fecha
        Object.assign(this.caja, resultado.caja)
        // obtengo los comprobantes
        await this.getComprobantes()
      } else {
        this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'error'
        })
      }
    },
    async getComprobantes () {
      this.search = ''
      this.selected = []
      this.comprobante_caja = []
      this.comprobante_pendientes = []

      this.load = true
      let resultado = await this.$store.dispatch('caja/get_comprobantes', this.caja.numero)
      this.load = false

      if (resultado.exito == 1) {
        this.comprobante_caja = resultado.comprobantes
        this.calcularTotales()
      } else {
        this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'error'
        })
      }

      // si la caja es de nivel 2 obtengo los comprobantes pendientes
      if (this.cajaNivel == 2) {
        this.load = true
        resultado = await this.$store.dispatch('caja/get_comprobantes_pendientes', this.caja.cj_numero)
        this.load = false

        if (resultado.exito == 1) {
          this.comprobante_pendientes = resultado.comprobantes
          this.calcularTotalesPendientes()
        } else {
          this.$store.dispatch('show_snackbar', {
            text: resultado.message,
            color: 'error'
          })
        }
      }
    },
    async getCaja () {
      this.limpiar()

      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/get_caja', { cj_numero: this.filtro.cj_numero })
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        this.filtro.numero = resultado.caja.numero
        this.filtro.fecha = resultado.caja.fecha
        this.caja.empresa = this.filtro.empresa
        this.caja.sucursal = this.filtro.sucursal
        Object.assign(this.caja, resultado.caja)
        this.existe_caja = true
        // obtengo los comprobantes y la ultima caja
        this.getComprobantes()
        this.setlastCaja()
      } else {
        this.limpiar(1)
        this.permite_abrirla = {
          valor: resultado.permite_abrirla,
          cj_numero: this.filtro.cj_numero
        }
        this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'error'
        })
      }
    },
    async setlastCaja () {
      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/last_caja', this.filtro.cj_numero)
      this.$store.state.loading = false

      if (resultado.exito == 1) {
        Object.assign(this.lastCaja, resultado.caja)
      } else {
        this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'error'
        })
      }
    },
    async buscar () {
      if (this.filtro.cj_numero == null && (this.filtro.numero == null || this.filtro.numero == '')) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione una caja o ingrese un Nº de caja',
          color: 'orange'
        })
      }

      this.limpiar()

      this.$store.state.loading = true
      let resultado = await this.$store.dispatch('caja/get_caja', {
        cj_numero: this.filtro.cj_numero,
        numero: this.filtro.numero,
        fecha: this.filtro.fecha
      })
      this.$store.state.loading = false

      if (resultado.exito != 1) {
        this.limpiar(1)
        this.permite_abrirla = {
          valor: resultado.permite_abrirla,
          cj_numero: this.filtro.cj_numero
        }
        return this.$store.dispatch('show_snackbar', {
          text: resultado.message,
          color: 'error'
        })
      }

      // si ingreso el nº de caja, verifico que la cj exista entre las cajas del usuario
      if (this.filtro.numero != null && this.filtro.numero != '') {
        let caja = this.cajas_user.filter(cu => cu.id == resultado.caja.cj_numero)
        if (caja.length == 1) {
          this.filtro.empresa = caja[0].empresa
          this.filtro.sucursal = caja[0].sucursal
        } else {
          return this.$store.dispatch('show_snackbar', {
            text: 'La caja ingresa no está disponible',
            color: 'error'
          })
        }
      }

      this.filtro.cj_numero = resultado.caja.cj_numero
      this.filtro.numero = resultado.caja.numero
      this.filtro.fecha = resultado.caja.fecha
      this.caja.empresa = this.filtro.empresa
      this.caja.sucursal = this.filtro.sucursal
      Object.assign(this.caja, resultado.caja)
      this.existe_caja = true

      // obtengo los comprobantes y la ultima caja
      this.getComprobantes()
      this.setlastCaja()

    },
    async cajaCerrada () {
      await this.getCaja()
      await this.imprimirCierre()
    },
    findCaja () {
      this.filtro.numero = JSON.parse(JSON.stringify(this.caja.numero))
      this.buscar()
    },
    calcularTotales () {
      this.total = 0
      this.total_efectivo = 0
      this.total_tarjeta = 0
      for (const cc of this.comprobante_caja) {
        this.total += parseFloat(cc.importe)
        this.total_efectivo += parseFloat(cc.efectivo)
        this.total_tarjeta += parseFloat(cc.tarjeta)
      }
      this.total = parseFloat(this.total.toFixed(2))
      this.total_efectivo = parseFloat(this.total_efectivo.toFixed(2))
      this.total_tarjeta = parseFloat(this.total_tarjeta.toFixed(2))
    },
    calcularTotalesPendientes () {
      this.total_pend = 0
      this.total_efectivo_pend = 0
      this.total_tarjeta_pend = 0
      for (const cc of this.comprobante_pendientes) {
        this.total_pend += parseFloat(cc.importe)
        this.total_efectivo_pend += parseFloat(cc.efectivo)
        this.total_tarjeta_pend += parseFloat(cc.tarjeta)
      }
      this.total_pend = parseFloat(this.total_pend.toFixed(2))
      this.total_efectivo_pend = parseFloat(this.total_efectivo_pend.toFixed(2))
      this.total_tarjeta_pend = parseFloat(this.total_tarjeta_pend.toFixed(2))
    },
    selectAllPendientes () {
      this.selected = []
      this.comprobante_pendientes.forEach(cp => { this.selected.push(cp) })
    },
    selectAllToggle () {
      if (this.selected.length < this.comprobante_pendientes.length) this.selectAllPendientes()
      else this.selected = []
    },
    openDeposito (datos) {
      this.comp_id = datos.comp_id
      this.depConcepto = datos.concepto
      this.depReferencia = datos.referencia
      this.depNumCmp = datos.num_comp
      this.depImporte = datos.importe
      this.dialog_deposito = true
    },
    verImagenes (item) {
      this.titulo = `${item.numero} - ${item.descripcion}`
      this.comp_id = item.id
      this.dialog_archivos = true
    },
    async confirmarEnvioMail (item) {
      this.$store.state.loading = true
      let datosPeticion = await this.$store.dispatch('caja/get_cliente_venta', {
        venta_id: item.numero
      })
      this.$store.state.loading = false
      if (datosPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: datosPeticion.message,
          color: 'error',
        })
        return
      }
      if (datosPeticion.datos.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontraron datos del Cliente para la venta seleccionada.',
          color: 'info',
        })
        return
      }
      // abro el modal
      this.dialogEnviarMail.cliente = datosPeticion.datos[0].nombre
      this.dialogEnviarMail.nro_comprobante = item.numero
      this.dialogEnviarMail.factura = item.descripcion
      this.dialogEnviarMail.email = datosPeticion.datos[0].email
      this.dialogEnviarMail.activo = true
    },
    limpiar (con_filtro) {
      this.search = ''
      this.tab = 0
      this.total = 0
      this.total_efectivo = 0
      this.total_tarjeta = 0
      this.total_pend = 0
      this.total_efectivo_pend = 0
      this.total_tarjeta_pend = 0
      this.existe_caja = false
      this.caja = JSON.parse(JSON.stringify(this.cajaLimpia))
      this.selected = []
      this.comprobante_caja = []
      this.comprobante_pendientes = []
      this.lastCaja = {
        estado: null,
        fecha: null,
        numero: null,
        transporte: null
      }
      this.permite_abrirla = {
        valor: 0,
        cj_numero: null
      }
      if (con_filtro == 1 || con_filtro == 2) {
        this.filtro.numero = null
        this.filtro.fecha = null
        this.keyFecha ++
      }
      if (con_filtro == 2){
        this.filtro.cj_numero = null
        this.filtro.sucursal = null
        this.filtro.empresa = null
      }
    },
    JSPMInit () {
      let _this = this
      JSPM.JSPrintManager.auto_reconnect = true
      JSPM.JSPrintManager.start()
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        _this.getPrinters().then((p) => {
          _this.printers = p
          _this.$nextTick()
        })
      }
    },
    getPrinters () {
      return new Promise((ok, err) => {
        let printers = []
        if(JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters().then(function (myPrinters) {
            printers = myPrinters
            //console.log(printers)
            ok(printers)
          }).catch((e) => err(e))
        } else {
          console.warn("JSPM WS not open")
          ok(printers)
        }
      })
    },
    JSPMStatus () {
      if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        return true
      }
      else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
        this.$swal.fire({
          icon: 'warning',
          title: 'JSPrintManager no se está ejecutando en esta máquina',
          text: 'Por favor instale los drivers proporcionados'
        })
        return false
      }
      else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
        this.$swal.fire({
          icon: 'error',
          title: 'JSPM a bloqueado este sitio!'
        })
        return false
      }
    },
    doPrintPDF (file) {
      if (this.JSPMStatus()) {
        let cpj = new JSPM.ClientPrintJob()
        cpj.clientPrinter = new JSPM.DefaultPrinter()
        let my_file = new JSPM.PrintFilePDF(ArrayBufferToBase64(file), JSPM.FileSourceType.Base64, 'MyFile.pdf', 1)
        cpj.files.push(my_file)
        cpj.sendToClient()
      }
    },
    setModalEnvEmail(value){
      this.dialogEnviarMail.activo = value
      if (this.dialogEnviarMail.activo == false){
        this.dialogEnviarMail.cliente = ''
        this.dialogEnviarMail.nro_comprobante = ''
        this.dialogEnviarMail.factura = ''
        this.dialogEnviarMail.email = ''
      }
    },
    setModalRetEfect(value){
      this.dialogExtracciones.activo = value
      if (this.dialogExtracciones.activo == false){
        this.dialogExtracciones.lapos_integrado = 0
        this.dialogExtracciones.lapos_desintegrado = 0
        this.dialogExtracciones.efectivo_disponible = 0
        this.dialogExtracciones.sucursal_codigo = 0
        this.dialogExtracciones.empresa_codigo = 0
        this.dialogExtracciones.canal_codigo = 0
        this.dialogExtracciones.pto_vta = 0
        this.dialogExtracciones.comp_caja_id = 0
        this.dialogExtracciones.nuevo = 0
      }
    },
    extraerEfectivo(){
      // tengo efectivo disponible?
      if (this.total_efectivo == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No posee efectivo en Caja.',
          color: 'info',
        })
        return
      }
      // lapos integrado?
      let cajaSelect = this.cajas_user.filter(element => element.id == this.filtro.cj_numero)
      this.dialogExtracciones.lapos_integrado = cajaSelect[0].fp_integrado
      this.dialogExtracciones.lapos_desintegrado = cajaSelect[0].fp_desintegrado
      // efectivo disponible
      this.dialogExtracciones.efectivo_disponible = this.total_efectivo
      // sucursal
      this.dialogExtracciones.sucursal_codigo = cajaSelect[0].sucursal
      // empresa
      this.dialogExtracciones.empresa_codigo = cajaSelect[0].empresa
      // canal
      this.dialogExtracciones.canal_codigo = cajaSelect[0].canal
      // pto de vta
      this.dialogExtracciones.pto_vta = cajaSelect[0].local
      // indico que es una nueva extraccion
      this.dialogExtracciones.nuevo = 1
      // abro el modal
      this.dialogExtracciones.activo = true
    },
    setRetiroEfectivo(res){
      // por el momento solo repito la busqueda
      this.buscar()
    },
    verExtraccion(item){
      // lapos integrado?
      let cajaSelect = this.cajas_user.filter(element => element.id == this.filtro.cj_numero)
      this.dialogExtracciones.lapos_integrado = cajaSelect[0].fp_integrado
      this.dialogExtracciones.lapos_desintegrado = cajaSelect[0].fp_desintegrado
      // efectivo disponible
      this.dialogExtracciones.efectivo_disponible = this.total_efectivo
      // sucursal
      this.dialogExtracciones.sucursal_codigo = cajaSelect[0].sucursal
      // empresa
      this.dialogExtracciones.empresa_codigo = cajaSelect[0].empresa
      // canal
      this.dialogExtracciones.canal_codigo = cajaSelect[0].canal
      // pto de vta
      this.dialogExtracciones.pto_vta = cajaSelect[0].local
      // indico que es para ver/reprocesar
      this.dialogExtracciones.nuevo = 0
      // indico que ya tengo comprobante caja id
      this.dialogExtracciones.comp_caja_id = item.id
      // abro el modal
      this.dialogExtracciones.activo = true
    },
    openWindow (id_venta) {
      const ruta_nueva = this.cajaCanal == 1 ? '/nueva-venta' : '/nueva-vta-mayorista'
      const ruta_edita = this.cajaCanal == 1 ? '/venta-salon' : '/vta-mayorista'
      const path = id_venta == null ? ruta_nueva : `${ruta_edita}/${id_venta}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>